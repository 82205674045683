.container {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 9999;
  padding: 10px;
  box-sizing: border-box;
  background-color: #dff2bf;
  color: #227700;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
}

.toastText {
  margin: 0 16px;
}

.closeIcon {
  cursor: pointer;
}
