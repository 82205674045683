.dd-wrapper {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}

.dd-header {
  cursor: pointer;
  position: relative;
  color: #ffffff;
  display: flex;
  width: 138px;
  justify-content: space-between;
}

.dd-header-title {
  width: 104px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  padding: 8px 0;
  display: inline-block;
  background-color: #1b87e6;
  color: #ffffff;
  text-transform: lowercase;
  border-radius: 2px 0 0 2px;
}
.dd-header-title::first-letter {
  text-transform: capitalize;
}

.arrow {
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1px;
  background-color: #1b87e6;
  border-radius: 0 2px 2px 0;
}

.upAndDownArrow {
  background-color: #1b87e6;
}
.dd-header-title:hover,
.arrow:hover,
.arrowActive {
  background-color: #54a5ec;
}

.dropDownOpen {
  border-bottom-left-radius: 0;
}

.arrowActive {
  border-bottom-right-radius: 0;
}

.angle-icon {
  position: absolute;
  right: -25px;
  top: 0;
  height: 100%;
  background-color: #1b87e6;
  color: #ffffff;
  padding: 0 8px;
  border-left: 1px solid #fff;
  cursor: pointer;
}
.angle-icon:hover {
  background-color: #54a5ec;
}

.angle-icon svg {
  margin-top: 3px;
}

.angle-icon-open {
  composes: angle-icon;
  background-color: #004693;
}

.dd-list {
  margin-left: 1px;
  margin-top: 5px;
  list-style: none;
  z-index: 10;
  position: absolute;
  border: 1px solid #1b87e6;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 5px -1px #e8e8e8;
  box-shadow: 0 2px 5px -1px #e8e8e8;
  max-height: 214px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  text-align: left;
  padding: 0;
  right: 0;
  max-width: 300px;
  min-width: 14px;
}

.dd-list-item {
  margin: 0;
  list-style: none;
  cursor: pointer;
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
}

.dd-list-item.selected {
  background-color: #f5f5f5;
}

.dd-list-item:hover {
  background-color: #f5f5f5;
}

.dd-list-item-first {
  composes: dd-list-item;
  color: #999999;
  position: relative;
}

.dd-list-item-first::after {
  position: absolute;
  content: '';
  border-bottom: 1px solid #d2d7da;
  width: 100%;
  transform: translateX(-50%);
  bottom: 0;
}
.sessionNameAndPin {
  text-align: left;
  display: flex;
}
.sessionName {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 4px;
  white-space: nowrap;
  max-width: 200px;
}
.pin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
}

.sessionTime {
  display: flex;
  width: 50px;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5px;
}
.presentWithIntegration {
  position: absolute;
  background: #fff;
  width: 136px;
  right: 0;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  padding-bottom: 0;
  border: 1px solid #1b87e6;
}
.presentWithIntegration:hover {
  visibility: visible;
}

.startNowText {
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
}
.startNowText:hover {
  background-color: #f5f5f5;
}
.presentWithIntegrationText {
  padding: 0 16px;
  padding-top: 8px;
  font-weight: 500;
}
.integration {
  font-weight: 400;
  border-bottom: 1px solid #ccc;
}
.integrationText {
  cursor: pointer;
  padding: 6px 16px;
  display: flex;
  height: 30px;
  align-items: center;
}
.integrationText:hover {
  background-color: #f5f5f5;
}
.integrationText:first-child {
  margin-top: 8px;
}
.integrationIcon {
  height: 16px;
  margin-right: 8px;
}
.activeSession {
  font-weight: 500;
  padding: 8px 16px;
}
.customSpinner {
  margin: 5px auto 0;
}
