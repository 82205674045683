.sortIcon {
  opacity: 0.3;
  margin-left: 5px;
}

.sortUpDown {
  color: #1b87e6;
  opacity: 1;
}

.activeTableHead {
  color: #1b87e6;
}

.hoverClass {
  cursor: pointer;
}