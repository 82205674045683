.container {
  margin: 16px;
  margin-left: 0;
}

.label {
  color: #545e6b;
  font-size: 12px;
  font-weight: 400;
  padding: 2px;
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
}

.required {
  color: #d93025;
  font-weight: 400;
}

.description {
  color: #9b9b9b;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
}

.line {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #9b9b9b;
}

.common {
  margin: 0;
}

.common:focus + .line {
  height: 2px;
  background-color: #1b87e6;
  animation: lineAnimation 0.3s linear forwards;
  border-bottom: none;
}

@keyframes lineAnimation {
  from {
    left: 50%;
    width: 0%;
  }

  to {
    left: 0%;
    width: 100%;
  }
}

/* *************** NumericInput css *************** */
.numericInputContainer {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
}

.numericInputContainerDisabled {
  composes: numericInputContainer;
  opacity: 0.5;
}

.numericInputInput {
  composes: common;
  background: #f2f2f2;
  padding: 7px;
  box-sizing: border-box;
  font-size: 14px;
  border: 1px solid transparent;
  display: block;
  -webkit-appearance: none;
  line-height: normal;
  width: 101px;
  outline: none;
}

.numericInputArrow {
  position: absolute;
  right: 2px;
  width: 2.26ex;
  text-align: center;
  cursor: default;
  transition: all 0.1s ease 0s;
  background: transparent;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.numericInputLeftArrow {
  composes: numericInputArrow;
  top: 0;
  bottom: 50%;
  border-radius: 2px 2px 0px 0px;
  border-width: 1px 1px 0px;
}

.numericInputRightArrow {
  composes: numericInputArrow;
  top: 50%;
  bottom: 2px;
  border-radius: 0px 0px 2px 2px;
  border-width: 0px 1px 1px;
}

.numericInputIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0px;
  height: 0px;
  color: #000000;
  border-style: solid;
  margin: -0.3ex 0px 0px -0.56ex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.numericInputLeftArrowIcon {
  composes: numericInputIcon;
  border-width: 0px 0.6ex 0.6ex;
  border-color: transparent transparent rgba(0, 0, 0, 0.7);
}

.numericInputRightArrowIcon {
  composes: numericInputIcon;
  border-width: 0.6ex 0.6ex 0px;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent;
}

.numericInputArrow:hover {
  cursor: pointer;
  background-color: #0000001f;
}

.numericInputContainerDisabled .numericInputArrow:hover {
  cursor: default;
  background-color: transparent;
}

.validationError {
  font-size: 10px;
  color: #ff0000;
  position: absolute;
  bottom: -16px;
  display: inline-block;
  width: 150px;
}

/* ***************** End NumericInput css *************** */

/* ***************** Start TextInput css *************** */

.textInputContainer {
  position: relative;
  display: inline-block;
  width: 100%;
}

.textInput {
  composes: common;
  background: #f5f5f5;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid transparent;
  display: block;
  width: 100%;
  -webkit-appearance: none;
  line-height: normal;
  outline: none;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}

.textInputCurrentLength {
  font-size: 11px;
  font-weight: 600;
  color: #ffffff;
  background-color: #5cb85c;
  padding: 1px 3px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  display: none;
}

.textInputMaxLengthReached {
  composes: textInputCurrentLength;
  background-color: #f0ad4e;
}

.textInput:focus + .textInputCurrentLength {
  display: inline-block;
  background-color: transparent;
}

/* ***************** End TextInput css *************** */

/* ***************** Start ToggleSwitch css *************** */

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 8px;
  background-color: #e8e8e8;
  border-radius: 30px;
  cursor: pointer;
}

.switch::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #9b9b9b;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  top: -5px;
  left: -3px;
  transition: all 0.3s;
}

.checkbox:checked + .switch::after {
  left: 20px;
  background-color: #1b87e6;
}

.checkbox:checked + .switch {
  background-color: #d8d8d8;
}

.checkbox {
  display: none;
}

.checkbox:disabled + .switch {
  opacity: 0.5;
  cursor: not-allowed;
}

/* ***************** End ToggleSwitch css *************** */

.leftIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-51%);
  left: 8px;
}

.textInputWithIcon {
  padding-left: 30px;
  font-size: 12px;
}
