.container {
  position: relative;
  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
  max-width: 650px;
  margin: auto;
}

.paginationContainer {
  position: absolute;
  right: 5px;
  top: 5px;
}

.tableContainer {
  overflow: auto;
  margin-top: 50px;
}

.includePagination {
  margin-top: 50px;
}

.livePollListTable {
  padding: 15px;
  border-collapse: collapse;
  overflow: auto;
}

.livePollListTable thead {
  background-color: #eee;
}

.livePollListTable th,
.livePollListTable td {
  font-size: 12px;
  width: 5%;
  text-align: left;
  min-width: 110px;
  padding: 0 15px;
  line-height: 30px;
  height: 40px;
  border-top-style: none;
}

.livePollListTable th {
  cursor: pointer;
  font-weight: 500;
}
/* .livePollListTable tbody tr {
} */

.livePollListTable td {
  border-bottom: 1px solid #d8d8d8;
  font-weight: normal;
  font-weight: 400;
}

.livePollListTable tr:hover {
  background-color: #f2f2f2;
}

.titleCell {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.titleLink {
  color: #545e6b;
}

.livePollListTable tr:hover .titleLink {
  color: #1b87e6;
  font-weight: bold;
}

.livePollListTable tr:hover .hoverOptionsIcons,
.livePollListTable tr .titleLink:hover {
  color: #004693;
}

.livePollListTable tr:hover .hoverOptionsIcons,
.livePollListTable tr:hover .verticalLineSeparator {
  visibility: visible;
}
.livePollListTable .startSessionText:hover {
  background-color: #d8d8d8;
  color: #1b3380;
}

.livePollListTable tr:hover .livePollTypeIcon {
  color: #545e6b;
}

.livePollListTable tr:hover .hoverOptionsIcons {
  color: #545e6b;
}

.livePollListTable .starredColumnHead {
  min-width: 75px;
  text-align: center;
}

.livePollListTable .typeColumnHead {
  min-width: 50px;
}
.livePollListTable .participantCountColumHead {
  min-width: 150px;
}

.livePollListTable .nameColumnHead {
  width: 15%;
}

.livePollListTable .hoverOptionsColumnHead {
  width: 30%;
  cursor: default;
}

.livePollListTable .lastActiveColumnHead {
  min-width: 70px;
}

.livePollListTable .sessionCountData {
  text-align: right;
  margin-right: 20px;
}
.livePollListTable .participantCountData {
  min-width: 100px;
  text-align: right;
  margin-right: 40px;
}
.livePollListTable .iconTableData {
  color: #ccc;
  padding: 0 15px;
  font-size: 14px;
  min-width: 50px;
}
.livePollListTable .lastSessionStartedAtTableData {
  min-width: 70px;
}


.hoverOptions {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 16px;
}

.livePollListTable .hoverOptionsIcons {
  margin: 0 8px;
  visibility: hidden;
  height: 24px;
  width: 24px;
  border-radius: 2px;
}

.livePollListTable .analyticsIconWrapper {
  display: inline-flex;
}
.livePollListTable .startSessionText {
  color: #1b87e6;
  font-size: 14px;
  padding: 8px 10px;
  border-radius: 2px;
  cursor: pointer;
}

.livePollListTable .iconTableData .hoverOptionsIcons:hover {
  cursor: pointer;
  background-color: #d8d8d8;
}

.inputBox {
  outline: none;
  padding: 6px 8px;
  border-color: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  border-width: 1px;
  font-size: 14px;
  opacity: 0.5;
}
.searchIconBox {
  height: 28px;
  width: 30px;
  position: absolute;
  right: 10px;
  background: #fff;
  margin-top: 1px;
}
.searchIcon {
  position: relative;
  top: 6px;
  left: 8px;
  opacity: 0.7;
}
.searchedLivePolls {
  height: 70px;
  overflow: auto;
  margin: 10px 0;
}
.searchedLivePolls .livePollTitle {
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px 0 0 5px;
}

.dropDownContentClass {
  min-width: 140px;
}
