.container {
  position: relative;
  background-color: #fff;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  max-width: 1250px;
  margin: auto;
}

.paginationContainer {
  position: absolute;
  right: 30px;
  top: 5px;
}

.tableContainer {
  overflow: auto;
}

.includePagination {
  margin-top: 50px;
}

.livePollListTable {
  padding: 15px;
  border-collapse: collapse;
  overflow: auto;
}

.livePollListTable thead {
  background-color: #eee;
}

.livePollListTable th,
.livePollListTable td {
  font-size: 12px;
  width: 5%;
  text-align: left;
  min-width: 110px;
  margin: 0 15px;
  line-height: 30px;
  height: 40px;
  border-top-style: none;
}

.livePollListTable th {
  cursor: pointer;
  font-weight: 500;
}

.livePollListTable td {
  border-bottom: 1px solid #d8d8d8;
  font-weight: normal;
  font-weight: 400;
}

.livePollListTable tr:hover {
  background-color: #f5f5f5;
}

.activeRow {
  background-color: #f2f2f2;
}

.titleCell {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.titleLink {
  color: #1b87e6;
}

.livePollListTable tr:hover .titleLink {
  color: #1b3380;
}

.livePollListTable tr:hover .hoverOptionsIcons,
.livePollListTable tr:hover .verticalLineSeparator,
.livePollListTable tr:hover .startSessionText {
  visibility: visible;
}
.livePollListTable .startSessionText:hover {
  color: #1b3380;
}

.livePollListTable tr:hover .livePollTypeIcon {
  color: #545e6b;
}

.livePollListTable .starredColumnHead {
  min-width: 75px;
  text-align: center;
}

.livePollListTable .typeColumnHead {
  text-align: center;
  min-width: 60px;
}
.livePollListTable .participantCountColumHead {
  min-width: 150px;
}

.livePollListTable .nameColumnHead {
  width: 40%;
}

.livePollListTable .hoverOptionsColumnHead {
  width: 30%;
  cursor: default;
}

.livePollListTable .sessionCountData {
  text-align: right;
  margin-right: 20px;
}
.livePollListTable .participantCountData {
  min-width: 100px;
  text-align: right;
  margin-right: 40px;
}
.livePollListTable .iconTableData {
  color: #545e6b;
  text-align: center;
  padding: 0;
  font-size: 14px;
  min-width: 115px;
}
.hoverOptions {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 16px;
}

.livePollListTable .hoverOptionsIcons {
  margin: 0 8px;
  visibility: hidden;
  height: 24px;
  width: 24px;
  border-radius: 2px;
}

.livePollListTable .analyticsIconWrapper {
  display: inline-flex;
}
.livePollListTable .startSessionText {
  visibility: hidden;
  color: #1b87e6;
  font-size: 14px;
  padding: 8px 10px;
  border-radius: 2px;
  cursor: pointer;
}

.livePollListTable .iconTableData .hoverOptionsIcons:hover {
  cursor: pointer;
  background-color: #eee;
}

.inputBox {
  outline: none;
  padding: 6px 8px;
  border-color: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  border-width: 1px;
  font-size: 14px;
  opacity: 0.5;
}
.searchIconBox {
  height: 28px;
  width: 30px;
  position: absolute;
  right: 10px;
  background: #fff;
  margin-top: 1px;
}
.searchIcon {
  position: relative;
  top: 6px;
  left: 8px;
  opacity: 0.7;
}
.searchedLivePolls {
  height: 70px;
  overflow: auto;
  margin: 10px 0;
}
.searchedLivePolls .livePollTitle {
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px 0 0 5px;
}

.dropDownContentClass {
  min-width: 140px;
}

.optionsContainer {
  font-size: 12px;
  color: #545e6b;
  width: 90px;
  flex-direction: column;
  position: absolute;
  background: #fff;
  border: 1px solid #1b87e6;
  z-index: 1;
  display: none;
}

.threeDots {
  display: none;
}
.option {
  height: 30px;
  padding-left: 8px;
  display: flex;
  align-items: center;
}
.option span {
  padding-left: 6px;
}
.option:hover {
  cursor: pointer;
  background-color: #e8e8e8;
}
.line {
  margin: 8px;
  border-bottom: 1px solid #d9d9d9;
}
.toggleButton {
  cursor: pointer;
  bottom: 1px;
  position: relative;
  height: 22px;
  width: 22px;
}
.toggleButton:hover {
  background-color: #d8d8d8;
}

.activeToggleButton {
  composes: toggleButton;
  visibility: visible;
}

@media only screen and (max-width: 1200px) {
  .livePollListTable .nameColumnHead {
    width: 25%;
  }
  .livePollListTable th,
  .livePollListTable td {
    width: 4%;
    min-width: 90px;
  }
  .participantCountColumHead {
    min-width: 90px;
  }
}

@media only screen and (max-width: 1150px) {
  .livePollListTable .nameColumnHead {
    width: 18%;
  }
}

@media only screen and (max-width: 1100px) {
  .livePollListTable .nameColumnHead {
    width: 15%;
  }
  .livePollListTable th,
  .livePollListTable td {
    width: 3%;
  }
}

@media only screen and (max-width: 1050px) {
  .livePollListTable .nameColumnHead {
    width: 13%;
  }
}
@media only screen and (max-width: 1000px) {
  .livePollListTable .nameColumnHead {
    width: 10%;
  }
}

@media screen and (max-width: 1025px) {
  .hoverOptions {
    display: none;
  }
  .threeDots {
    display: flex;
    justify-content: space-around;
    height: 16px;
  }
  .livePollListTable .iconTableData {
    min-width: 25px;
  }

  .livePollListTable .nameColumnHead {
    width: 40%;
  }
  .livePollListTable th,
  .livePollListTable td {
    width: 5%;
    min-width: 115px;
  }
  .participantCountColumHead {
    min-width: 150px;
  }
  .livePollListTable .hoverOptionsColumnHead {
    width: 5%;
    min-width: 25px;
  }
}
