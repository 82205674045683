.emptyState {
  margin-top: 16px;
}
.welcomeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

.welcomeMessage {
  font-weight: lighter;
  font-size: 24px;
  color: #545e6b;
}

.welcomeImage {
  height: 56px;
  margin: 24px 0;
}

.createLivePollCard {
  width: 208px;
}

.createLivePollCard svg {
  color: #ccc;
}

.createLivePollCard:hover svg {
  color: #1b87e6;
}

.container {
  margin: 15px auto;
  padding-top: 15px;
  max-width: 1210px;
}

.container > span {
  font-size: 14px;
}

.gridItem {
  height: 125px;
  margin: 15px 42px 15px 0;
  min-width: 208px;
  width: 208px;
}

.gridItem > div {
  height: 100%;
}

.primaryButtonContainer {
  position: relative;
  background-color: #ffffff;
  color: #1b87e6;
  cursor: pointer;
  text-align: center;
  padding: 11px 0;
}

.primaryButtonContainer:hover {
  color: #004693 !important;
}

.addLivePollModalOptionIcon:hover {
  color: #1b87e6;
  cursor: pointer;
  background-color: #d5e6f5;
}

.addLivePollModalOptionType {
  font-weight: 600;
}

.addLivePollModalOptionType,
.addLivePollModalOptionDesc {
  padding: 10px 0;
}

.dialogHeading {
  justify-content: space-between;
  display: flex;
  background-color: #f4f4f4;
  color: #555555;
  padding: 10px 15px;
}

.headerText {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  flex-grow: 1;
}

.optionName {
  margin-top: 5px;
  text-align: center;
  font-size: 16px;
}

.addLivePollModalContainer {
  text-align: center;
}

.addLivePollModalContainer1 {
  text-align: center;
  display: flex;
  padding: 40px;
}

.padR20 {
  padding-right: 20px;
}

.padL20 {
  padding-left: 20px;
}

.optionCardBox {
  border: 1px solid #e1e1e1;
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
  cursor: pointer;
  width: 200px;
  float: left;
  padding: 10px;
}

.optionCardBoxText {
  font-size: 12px;
  color: #9b9b9b !important;
  padding: 10px 0;
  text-align: center;
  display: flex;
  justify-content: center;
}

.optionCardBoxIcon {
  opacity: 0.8;
  width: 64px !important;
  height: 4em !important;
}

.closeButton {
  color: #1b87e6;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 15px;
}

.closeButton :hover {
  opacity: 0.5;
  color: #c00;
}

.optionCardBox:hover > div {
  color: #1b87e6 !important;
}

.lineSeparator {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 8px 0 12px 0;
}

.homeScreenContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
}

.containerWrapper {
  overflow: auto;
  height: 100vh;
}
