.card {
  transition: 0.3s;
  box-sizing: border-box;
  border: 1px solid #ccc;
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 8px;
  border-top: 8px solid #ccc;
}

.container {
  padding: 10px 12px;
}

.selectable {
  -webkit-transition: background-color 0.3s ease-out;
  -moz-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
}

.selectable:hover {
  cursor: pointer;
  border: 1px solid #1b87e6;
  border-top: 8px solid #1b87e6;
}

.cardTitle {
  font-size: 12px;
  font-weight: 500;
  width: 85%;
}

.cardSubtitle {
  font-size: 12px;
  font-weight: 300;
  color: #7d7d7d;
}

.cardBody {
  margin: 20px 0 24px 0;
}

.cardFooter {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 10px 12px;
  box-sizing: border-box;
  width: 100%;
  font-size: 12px;
  background-color: #ffffff;
}

.cornerIcon {
  position: absolute;
  right: 0;
  padding: 5px 12px;
  color: #cccccc;
}

.cardDrawer {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2px 16px;
  box-sizing: border-box;
  transform: translateX(100%);
  width: 100%;
  z-index: 1;
  background-color: #f4f4f4;
  cursor: default;
  border-top: #ccc 1px solid;
}

.cardDrawerClose {
  composes: cardDrawer;
  animation: slideIn 0.3s forwards;
}

@keyframes slideOut {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.cardDrawerOpen {
  composes: cardDrawer;
  animation: slideOut 0.3s forwards;
}

@keyframes slideIn {
  0% {
    -webkit-transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(100%);
  }
}

.cardDrawerToggle {
  margin-left: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 2px 3px;
  z-index: 2;
}

.cardDrawerToggleOpen {
  composes: cardDrawerToggle;
}
