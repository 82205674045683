.navContainer {
  width: auto;
  background: #fff;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  align-items: center;
}

.activeClass {
  border-bottom: 2px solid #20a2dc;
}

.navContainer ul li {
  list-style: none;
  padding: 0 15px;
  font-size: 14px;
  color: #004693;
  text-align: center;
  line-height: 30px;
  -webkit-transition: background-color 0.3s ease-out;
  -moz-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
}

.navContainer ul li:hover {
  cursor: pointer;
  color: #0095dc;
  background: #f4f4f4;
}

.navContainer ul {
  display: flex;
  margin: 0;
  padding: 0;
}

.secondaryNavbar {
  display: flex;
  border-top: 1px solid #dcdcdc;
  padding: 0 15px 0 15px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.navContainerHasSecondaryNav {
  composes: navContainer;
  box-shadow: none;
}

.secondaryNavbarItem {
  display: inline-block;
  width: 100px;
  text-align: center;
  padding-top: 2px;
}

.secondaryNavbarItem:hover {
  background: #e1e1e1;
  transition: background-color 0.15s ease;
  cursor: pointer;
}

.secondaryNavbarItemActive {
  composes: secondaryNavbarItem;
  background-color: #eee;
}

.secondaryNavbarItemActive:hover {
  background-color: #eee;
}

.secondaryNavbarItem img {
  height: 25px;
  width: 25px;
}

.secondaryNavbarItem span {
  font-size: 10px;
  width: 100%;
  color: #87919e;
  line-height: 18px;
  text-align: center;
  vertical-align: top;
  display: flex;
  justify-content: center;
}

.secondaryNavbarRight {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}
