.gridItem {
  height: 120px;
  margin: 8px 31px 8px 0;
  min-width: 190px;
  max-width: 210px;
}

.gridItem > div {
  height: 100%;
}

.card {
  transition: 0.3s;
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  position: relative;
  background-color: #f5f5f5;
  overflow: hidden;
  border-radius: 4px;
  text-align: left;
}

.card:hover {
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 3px 5px 0 rgba(216, 216, 216, 0.8);
}

.row {
  display: flex;
  margin: 8px;
  justify-content: space-between;
  color: #fff;
  position: absolute;
  top: 0;
  width: 190px;
}
.iconAndTemplateType {
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconAndTemplateType .icon {
  height: 16px;
  width: 16px;
  color: #f5f5f5;
}
.iconAndTemplateType span {
  margin-left: 2px;
  font-weight: 600;
}
.totalQuestions {
  background: rgba(245, 245, 245, 0.5);
  align-items: center;
  display: flex;
  border-radius: 2px;
}
.totalQuestions span {
  font-size: 12px;
  padding: 4px 8px;
  color: #fff;
}
.templateTitle {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 8px;
  box-sizing: border-box;
  width: 100%;
  font-size: 12px;
  background: #fff;
  height: 34px;
  text-align: left;
  text-transform: capitalize;
}

.cornerIcon {
  position: absolute;
  right: 0;
  padding: 5px 12px;
  color: #cccccc;
}

@keyframes slideOut {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes slideIn {
  0% {
    -webkit-transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(100%);
  }
}
