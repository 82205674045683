.listItem {
  padding: 10px;
  width: 140px;
}

.listItem:hover {
  background-color: #e8e8e8;
  cursor: pointer;
}

.validationError {
  color: red;
}

.sessionNameLabel {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
}
.sessionNameLength {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 300;
  position: absolute;
  right: 40px;
  top: 56px;
}

.speakerNotesDesc {
  margin-top: 16px;
  display: flex;
  height: 16px;
}
.speakerNotesDesc img {
  margin-right: 8px;
}
.speakerNotesDescForQuiz {
  composes: speakerNotesDesc;
  max-width: 100%;
  justify-content: flex-start;
}
.modal {
  width: 684px;
}
.toggle {
  margin-top: 8px;
}
