.popupContent {
  padding: 30px;
  min-height: 50px;
  position: relative;
}
.container {
  padding: 16px;
  width: 800px;
  background-color: #f5f5f5;
  height: 450px;
}
.innerContainer {
  background-color: #fff;
  max-height: 450px;
  overflow: auto;
}
.question {
  padding: 0 16px;
}
.questionTitle {
  font-size: 16px;
  font-weight: 500;
  padding: 16px 0;
  text-align: left;
}

.answer {
  padding-left: 16px;
  font-size: 14px;
  padding-bottom: 8px;
  display: flex;
  line-height: 18px;
  align-items: center;
  position: relative;
}
.radioButton {
  position: absolute;
  top: -8px;
  left: 0;
}
.answerCircle {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid #1b87e6;
}
.correctCircle {
  height: 12px;
  width: 12px;
  background: #1b87e6;
  border-radius: 50%;
  margin: 3px 0 0 3px;
}

.answerText {
  margin-left: 8px;
}
.borderBottom {
  border-bottom: 1px solid #d8d8d8;
  margin: 16px 0 8px 0;
}
