.container {
  display: flex;
  align-items: center;
  margin: 10px 0;
  background-color: #fff;
}

.previousBtn,
.nextBtn {
  background-color: #fff;
  border: none;
  color: #9b9b9b;
  margin: 0 10px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previousBtn:hover,
.nextBtn:hover {
  cursor: pointer;
  background-color: #e8e8e8;
  color: #555;  
}

.perPageContainer {
  border-top: 1px solid #ccc;
  margin-top: 5px;
}

.pageRangeTitle,
.perPageTitle {
  display: inline-block;
  font-size: 12x;
  color: #545e6b;
  font-weight: 500;
  white-space: pre;
  padding: 12px 8px 6px;
}

.list {
  max-height: 180px;
  overflow: auto;
  /*custom scrollbar for firefox*/
  scrollbar-width: thin;
  scrollbar-color: #ccc #fff;
}

.list li {
  padding: 10px 12px;
}

.list li:hover {
  background-color: #e8e8e8;
  cursor: pointer;
}

/*custom scrollbar for webkit browsers, e.g: chrome, safari, edge*/
.list::-webkit-scrollbar {
  width: 5px;
}
.list::-webkit-scrollbar-thumb {
  background-color: #9B9B9B;
}
.list::-webkit-scrollbar-button {
  height: 2px;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

@media only screen and (max-width: 600px) {

.pageRangeTitle,
.perPageTitle {
  white-space: normal;
}

}