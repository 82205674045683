.header {
  width: 100%;
  position: relative;
  left: 0;
  top: 0;
  height: 48px;
  margin-right: 100px;
  z-index: 2;
}

@media only screen and (min-width: 768px) {
  .header {
    position: relative;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    position: relative;
  }
}

.header .topHeader {
  background: #454545;
  border: none;
  display: block;
  font-size: 0;
  padding: 10px 15px;
  position: relative;
  height: 28px !important;
}

.admin {
  position: absolute;
  top: 10px;
  right: 90px;
  width: auto;
  padding: 4px 8px;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  z-index: 999;
  text-decoration: none;
}
.activeActivePath {
  composes: admin;
  background-color: #fff;
  color: #1b87e6;
  font-weight: 500;
}

.admin:hover {
  background-color: #fff;
  color: #1b87e6;
}

.activeActivePath:hover {
  color: #454545;
}

.customHeader {
  align-items: center;
  display: flex;
  height: 48px;
  position: relative;
  width: 100%;
  z-index: 999;
  background: #454545;
  display: flex;
  justify-content: space-between;
}

.userInitials {
  border: 1px solid #fff;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  color: #fff;
  position: absolute;
  right: 15px;
  top: -14px;
}
.userInitialsText {
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}
.userInitials:hover {
  background-color: #fff;
}

.userInitials:hover > .userInitialsText {
  color: #454545;
}
.customHeaderRightSide {
  position: relative;
}
.customHeaderLeftSide {
  background-color: #1b87e6;
  font-size: 18px;
  font-weight: 300;
  color: #fff;
  height: 48px;
  align-items: center;
  display: flex;
}
.customHeaderLeftSide:hover {
  background-color: #676767;
}
.logoContainer {
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  background-image: url(https://www.questionpro.com/images/appnavigation/qp-logo.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 70% 70%;
  display: inline-block;
  height: 28px;
  width: 28px;
  cursor: pointer;
}
.customHeaderText {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.downArrow {
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.categoryModal {
  height: 300px;
  width: 310px;
  position: absolute;
  top: 48px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  list-style: none;
  transition: all 0.3s ease-in-out;
  width: 310px;
}
.accountModal {
  width: 315px;
  height: 435px;
  background-color: #fff;
  border-left: 1.5px solid #adadad;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  position: absolute;
  transition: all 0.3s ease-in-out;
  right: 0;
  top: 48px;
}
