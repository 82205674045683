.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.titleText {
  font-size: 16px;
  margin-top: 40px;

}
.participant, .host {
  width: 80%;
  padding: 20px 25px 20px 20px;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  border-left: 8px solid transparent;
  background-color: #fff;
}

.participant:hover, .host:hover {
  cursor: pointer;
  border: 1px solid #1b87e6;
  border-left: 8px solid #1b87e6;
}

.participant {
  margin: 30px 0;
}

.screenUserImg, .presenterImg {
  width: 64px;
  height: 64px;
}

.heading {
 font-size: 18px;
 font-weight: 600;
 margin-left: 24px;
}

.informationText {
  display: block;
  margin-left: 24px;
  margin-top: 5px;
  font-size: 14px;
}

.knowMoreText {
  margin-top: 30px;
}

.poweredByText {
  position: absolute;
  bottom: 40px;
}

.poweredByText .companyName {
  font-weight: bold;
}

@media only screen and (min-width: 768px) {
  .participant, .host {
    width: 70%;
  }
}

@media only screen and (min-width: 992px) {
  .participant, .host {
    width: 60%;
  }
}

@media only screen and (min-width: 1200px) {
  .participant, .host {
    width: 40%;
  }
}