.topBarContainer {
  background-color: #fff;
  width: 100%;
  min-height: 48px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  z-index: 2;
  justify-content: flex-end;
}
.newLivePollContainer {
  padding-right: 16px;
}

.sortIcon {
  margin-left: 10px;
}

@media only screen and (max-width: 480px) {
  .newLivePollContainer {
    padding-right: 0;
  }
}
