.container {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  padding: 2.4rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: rgba(0, 0, 0, 0.75);
  transition: 0.2s;
}

.innerContainer {
  align-self: flex-start;
  max-width: 48rem;
  margin: auto;
  background-color: #fff;
  transition: 0.4s;
}

.popupHeading {
  justify-content: space-between;
  display: flex;
  background-color: #f4f4f4;
  color: #555555;
  padding: 10px 15px;
  align-items: center;
}

.headerText {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  flex-grow: 1;
  text-transform: lowercase;
}

.headerText::first-letter {
  text-transform: uppercase;
}

.popupContent {
  padding: 24px 30px;
  min-height: 50px;
  position: relative;
  color: #545e6b;
  width: 500px;
}

.ratingText {
  color: #545e6b;
  font-size: 14px;
  font-weight: 500;
  margin: 0 16px 4px;
}

.textInput {
  border: 1px solid #9b9b9b;
  width: calc(100% - 32px);
  font-size: 14px;
  font-weight: 400;
  color: #1d1b1b;
  overflow: auto;
  height: 100px;
  min-height: 100px;
  max-height: 250px;
  line-height: 16px;
  resize: none;
  background-color: #eeeeee;
  box-sizing: border-box;
  padding: 8px;
  margin: 0 16px;
  font-family: 'Fira sans';
}
.characterLength {
  margin: 0 16px;
  justify-content: flex-end;
  display: flex;
  margin-top: 4px;
}

.close {
  cursor: pointer;
}
.buttonRow {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  margin-right: 16px;
}
.cancelLink {
  align-items: center;
  border-radius: 2px;
  color: #1b87e6;
  cursor: pointer;
  display: flex;
  margin-right: 24px;
  padding: 8px 16px;
  font-size: 14px;
}
.cancelLink:hover {
  background-color: #f5f5f5;
}
.button {
  width: 90px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  padding: 8px 0;
  display: inline-block;
  background-color: #1b87e6;
  color: #ffffff;
  border-radius: 2px 0 0 2px;
  cursor: pointer;
}
.button:hover {
  background-color: #54a5ec;
}
.disableButton {
  composes: button;
  background-color: #eeeeee;
  cursor: not-allowed;
  color: #9b9b9b;
}
.disableButton:hover {
  background-color: #eeeeee;
}
.rate {
  float: left;
  height: 46px;
  margin: 0 0 16px 16px;
}
.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}
.rate:not(:checked) > label:before {
  content: '★ ';
}
.rate > input:checked ~ label {
  color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}

.spinnerPosition {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.spinner {
  margin: 0px;
}
