.colorPicker {
  padding: 0;
  border: 1px solid #d8d8d8;
  background-color: transparent;
  height: 24px;
  width: 24px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  position: relative;
}
.colorPicker input[type='color'] {
  position: absolute;
  height: 34px;
  width: 34px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
