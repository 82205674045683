.searchTopic {
  margin: 16px 0 8px;
  border-bottom: 1px solid #d8d8d8;
  text-align: left;
  display: flex;
}
.container {
  display: flex;
}
.categoryRow {
  width: 230px;
  height: 500px;
}
.templateForText {
  margin-bottom: 6px;
  text-align: left;
}
.quizAndPollToggle {
  background-color: #eee;
  display: flex;
  justify-content: center;
  height: 32px;
  align-items: center;
  cursor: pointer;
  width: 230px;
  border-radius: 16px;
}

.templateType {
  width: 50%;
  text-align: center;
  padding: 10px 0;
}
.activeTemplateType {
  background-color: #1b87e6;
  color: #fff;
  cursor: default;
}
.activeType {
  border-radius: 16px;
}

.categoryTitle {
  padding: 8px 10px;
  line-height: 18px;
  width: 215px;
  cursor: pointer;
  margin-right: 10px;
  text-align: left;
}
.activeCategory {
  font-weight: bold;
}
.categoryTitle:hover {
  background-color: #eee;
}
.templateContainer {
  display: flex;
  flex-flow: row wrap;
  height: 500px;
  overflow: auto;
  padding: 5px;
  margin: 5px 16px;
  min-width: 705px;
  place-content: flex-start;
}

.emptyTemplateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100%;
}
.gridItem {
  margin: 10px;
  height: 125px;
  width: 208px;
}
.gridItem > div {
  height: 100%;
}
