.dropdown {
  border-radius: 2px;
  padding: 8px;
  position: absolute;
  top: 105px;
  min-width: 120px;
  background: #fff;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  left: 105px;
}

.sortBy {
  margin-top: 8px;
  cursor: pointer;
}

.sortByActiveColor {
  color: #1b87e6;
}
.sortByText {
  margin-left: 8px;
}
.noLivePollFound {
  margin: 8px 5px;
}
.divider {
  margin-top: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.filterText {
  font-weight: 700;
  margin: 10px 0 15px 0;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.clearButton {
  color: #1b87e6;
  cursor: pointer;
}

.inputBox {
  outline: none;
  padding: 6px 8px;
  border-color: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  border-width: 1px;
  font-size: 14px;
  opacity: 0.5;
}
.searchIconBox {
  height: 28px;
  width: 30px;
  position: absolute;
  right: 10px;
  background: #fff;
  margin-top: 1px;
}
.searchIcon {
  position: relative;
  top: 6px;
  left: 8px;
  opacity: 0.7;
}
.searchedLivePolls {
  height: 70px;
  overflow: auto;
  margin: 10px 0;
}
.searchedLivePolls .livePollTitle {
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px 0 0 5px;
}
