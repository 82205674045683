.container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background: white;
}

.container h2 {
    color: #8b0000;
}

.backLink {
    cursor: pointer;
    color: #555;
    text-decoration: underline;
}

.backLink:hover {
    text-decoration: none;
    font-weight: 600;
}