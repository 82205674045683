.optionCard {
  border: 1px solid #e1e1e1;
  position: relative;
  background-color: #ffffff;
  overflow: hidden;
  cursor: pointer;
  width: 325px;
  padding: 10px;
  float: left;
  margin: 15px 15px 40px;
}

.optionCard:hover {
  color: #1b87e6;
}

.container {
  padding: 10px 16px;
  font-size: 12px;
  color: #545e6b;
  text-align: center;
  font-weight: 400;
  opacity: 0.6;
}

.name {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.icon,
.image {
  text-align: center;
  padding: 10px;
}
