.spinnerPosition {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.spinner {
  margin: 0px;
}

.popup {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  padding: 2.4rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: rgba(0, 0, 0, 0.75);
  transition: 0.2s;
}

.popup_inner {
  align-self: flex-start;
  max-width: 48rem;
  margin: auto;
  background-color: #fff;
  transition: 0.4s;
}

.popupHeading {
  justify-content: space-between;
  display: flex;
  background-color: #f4f4f4;
  color: #555555;
  padding: 10px 15px;
  align-items: center;
}

.headerText {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  flex-grow: 1;
  text-transform: lowercase;
}

.headerText::first-letter {
  text-transform: uppercase;
}
.betaFeature {
  position: relative;
  top: 6px;
  margin-left: 4px;
}
.popupContent {
  padding: 24px 30px;
  min-height: 50px;
  position: relative;
  color: #545e6b;
}

.popup pre {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.close {
  color: #909090;
  cursor: pointer;
  font-size: 24px;
}

.close:hover {
  color: #555555;
}

.submitButtonContainer {
  text-align: right;
  padding-top: 16px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 50px;
}
.footerLeftText {
  align-items: center;
  display: flex;
}
.cancelAndSubmitBtn {
  display: flex;
}
.cancelLink {
  display: flex;
  align-items: center;
  margin-right: 25px;
  color: #1b87e6;
  cursor: pointer;
  padding: 10px;
  border-radius: 2px;
}
.cancelLink:hover {
  background-color: #f5f5f5;
}

.horizontalLine {
  height: 1px;
  background-color: #d8d8d8;
  margin-top: 32px;
}
