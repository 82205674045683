.secondaryNavbar {
  border-top: 1px solid #dcdcdc;
  padding: 0 15px 0 15px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  height: 45px;
}

.secondaryNavbarItem {
  display: inline-block;
  width: 100px;
  height: 45px;
  text-align: center;
  padding: 2px;
  box-sizing: border-box;
}

.secondaryNavbarItem:hover {
  background: #e1e1e1;
  transition: background-color 0.15s ease;
  cursor: pointer;
}

.secondaryNavbarItemActive {
  composes: secondaryNavbarItem;
  background-color: #eee;
}

.secondaryNavbarItemActive:hover {
  background-color: #eee;
}

.secondaryNavbarItem img {
  height: 25px;
  width: 25px;
  width: 100%;
}

.secondaryNavbarItem span {
  font-size: 10px;
  width: 100%;
  color: #87919e;
  line-height: 14px;
  text-align: center;
  vertical-align: top;
}

.isSaving {
  margin-right: 16px;
}
.isSaving span {
  margin-left: 5px;
}
.allChangesSavedText {
  margin-right: 16px;
  animation: hideAfterSecond 2s linear forwards;
}
.allChangesSavedText span {
  margin-left: 5px;
}

@keyframes hideAfterSecond {
  0% {
    visibility: visible;
  }
  25% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.right {
  float: right;
}
.permanentRoom {
  display: flex;
  font-size: 14px;
  align-items: center;
}
.permanentRoomToggle {
  display: flex;
}
.toggle {
  margin-left: 8px;
  display: flex;
}
.permanentRoomUrl {
  display: flex;
  margin: 0 16px;
  align-items: center;
  justify-content: space-between;
  width: 72px;
}
.addedToPresentationText {
  font-size: 14px;
  color: #ccc;
  font-style: italic;
}
