.teamSettingsContainer {
  display: flex;
}

.teamSettingsSectionOne {
  display: flex;
  flex-direction: column;
}

.listItem {
  padding: 10px;
  width: 40px;
  height: 34px;
}

.listItem:hover {
  background-color: #ccc;
  cursor: pointer;
}

.teamSettingsSectionTwo {
  margin: 0 15px 0 50px;
  width: 270px;
}

.teamNameContainer {
  margin-bottom: 16px;
  position: relative;
}
.teamNameLength {
  position: absolute;
  top: 8px;
  right: 10px;
}
.teamColorPickerContainer {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 12px;
}

.teamColorPickerWrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.customColorPicker {
  opacity: 0;
  width: 30px;
  height: 30px;
  margin: 1px;
}
