.container {
    display: inline-block;
    border: none;
    background-color: transparent;
    color: inherit;
    font-size: inherit;
    padding: 0;
    margin: 0;
}

.container:hover {
    color: #1b87e6;
    cursor: pointer;
}

.primary {
    padding: 5px;
}