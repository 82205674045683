.addLivePollModalContainer {
  text-align: center;
  width: 755px;
}
.templateRow {
  width: 725px;
  margin-left: 16px;
}
.gridItem {
  margin: 10px 20px 10px 0;
  height: 125px;
  width: 208px;
}
.gridItem > div {
  height: 100%;
}

