.gridContainer {
  display: flex;
  flex-wrap: wrap;
}

.gridContainer div:last-child {
  margin-right: 0;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  max-width: 1210px;
  margin: auto;
}

.topRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.templatesText {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.templatesText:hover {
  opacity: 0.8;
  cursor: pointer;
}

.seeAllTemplate {
  display: flex;
  color: #1b87e6;
  align-items: center;
  font-size: 14px;
}

.seeAllTemplate:hover {
  color: #22b6ff;
  cursor: pointer;
}

.recentLivePollsContainer > span {
  font-size: 14px;
}

.icon {   
  width: 12px;
  height: 12px;
  margin-left: 8px;
}