.footer {
  z-index: 5;
  background: #ffffff;
  border-top: 1px solid #ccc;
  position: relative;
  padding: 8px 24px;
  display: flex;
}
.leftSide {
  display: flex;
  width: 400px;
}
.tip {
  display: block;
  width: 400px;
  text-align: right;
}
.container {
  font-weight: 300;
  font-size: 12px;
  color: #555;
}
.showOnWaitingRoom {
  composes: container;
}
.companyName {
  font-weight: 800;
}
.feedbackContainer {
  color: #1b87e6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  margin: 0 auto;
}
.rightSide {
  width: 400px;
}

@media screen and (max-width: 1000px) {
  .leftSide {
    width: 300px;
  }
  .rightSide,
  .tip {
    width: 300px;
  }
}

@media screen and (max-width: 768px) {
  .leftSide,
  .rightSide {
    width: 200px;
  }
  .tip {
    width: 200px;
  }
  .feedbackContainer {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .leftSide,
  .rightSide {
    width: 150px;
  }
  .tip {
    width: 150px;
  }
}

@media screen and (max-width: 400px) {
  .leftSide,
  .rightSide {
    width: 140px;
  }
  .tip {
    width: 140px;
  }
}
