.spinner {
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.loaderMessage {
  text-align: center;
  font-size: 0.8em;
  margin-top: 25px;
  font-size: 14px;
}
